<template>
  <div v-if="fetchMixin.state === fetchMixin.STATES.ERROR"></div>
  <div v-else>
    <v-card flat tile :loading="fetchMixin.state === fetchMixin.state.LOADING">
      <v-card-title class="text-h4">
        <v-row no-gutters>
          <v-col v-if="weekEvent != null">
            {{ $t('common.words.event') }}: {{ $t(`weekType.${weekEvent.weekType}`) }} {{ weekEvent.weekNumber }}
          </v-col>
          <v-spacer v-else/>

          <v-btn
            text color="primary"
            @click="$router.push('/weekEvents')"
          >
            {{ $t('common.action.back') }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text v-if="weekEvent != null">
        <v-btn
          color="primary"
          @click.prevent="update"
          :disabled="!edited || isLoading" :loading="isLoading"
        >
          {{ $t('common.action.save') }}
        </v-btn>
      </v-card-text>

      <v-card-text v-if="weekEvent != null">
        <week-event-form-list :value="weekEvent.formIds" @input="updateFormIds"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fech.mixin';
import WeekEventService from '@/services/api/weekEvent.service';
import WeekEventFormList from '@/components/weekevent/WeekEventFormList.vue';
import UpdateMixin from '@/mixins/update.mixin';

export default {
  name: 'form-info',
  mixins: [FetchMixin, UpdateMixin],
  components: { WeekEventFormList },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    weekEvent: null,
    edited: false,
  }),
  methods: {
    fetchWeekEvent(id) {
      return WeekEventService.fetchWeekEvent(id);
    },
    updateFormIds(formIds) {
      this.edited = true;
      this.weekEvent.formIds = formIds;
    },
    updateWeekEvent() {
      return WeekEventService.updateWeekEvent(this.weekEvent.id, this.weekEvent);
    },
    weekEventUpdated() {
      this.edited = this.isError;
    },
  },
  created() {
    // Setup mixins
    this.fetchMixin.config = {
      dataFieldOne: 'weekEvent',
      fetchById: 'fetchWeekEvent',
    };

    this.updateMixin.config = {
      update: 'updateWeekEvent',
      updateCallback: 'weekEventUpdated',
    };

    // Load or use value
    if (this.value === null) {
      this.fetchById(this.$route.params.weekEventId);
      return;
    }
    this.weekEvent = { ...this.value };
  },
};
</script>

<style scoped>
</style>
