<template>
  <v-col class="pa-0">
    <v-list style="width: 100%">
      <v-scroll-x-transition group>
        <v-list-item
          v-for="(formId, i) in value" :key="formId"
          dense class="px-0"
        >
          <v-list-item-content>
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters align="center">
                  <v-col>
                    {{ $t('common.words.form') }} #{{ formId }}
                  </v-col>
                  <v-btn
                    :disabled="i <= 0"
                    icon color="primary"
                    @click="swap(i, i - 1)"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>

                  <v-btn
                    :disabled="i + 1 >= value.length"
                    icon color="primary"
                    @click="swap(i, i + 1)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>

                  <confirm-button width="400" @click="remove(i)">
                    <template v-slot:button="{ on, attrs }">
                      <v-btn
                        fab depressed small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:title style="word-wrap: break-word">
                      {{ $t('pages.week_events.delete_validation_1') }}<br/>
                      {{ $t('pages.week_events.delete_validation_2') }}
                    </template>

                    <template v-slot:message>
                      {{ $t('pages.week_events.delete_validation_message') }}
                    </template>
                  </confirm-button>
                </v-row>
                <v-divider class="mt-4"/>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-scroll-x-transition>
    </v-list>

    <v-form v-model="valid" ref="form">
      <v-row no-gutters align="center">
        <form-select
          v-model="formId"
          class="pr-8"
          :rules="[(v) => !value.includes(v) || $t('pages.week_events.form_in_event_error')]"
        />
        <!--      <v-spacer/>-->
        <v-btn
          fab depressed small color="primary"
          :disabled="!valid"
          @click="add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import FormSelect from '@/components/forms/FormSelect.vue';

export default {
  name: 'week-event-form-list',
  components: {
    FormSelect,
    ConfirmButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    formId: null,
    valid: false,
  }),
  methods: {
    async add() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      this.updateValue([...this.value, this.formId]);
    },
    remove(i) {
      const value = [...this.value.slice(0, i), ...this.value.slice(i + 1)];
      this.updateValue(value);
    },
    swap(i, j) {
      const value = [...this.value];
      [value[i], value[j]] = [value[j], value[i]];

      this.updateValue(value);
    },
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
